<template>
    <div class="new-classroom column">
        <div class="row">
            <span class="legend">{{name || $t('class')}}</span>
        </div>
		<div class="row">
			<label>{{$t('class')}}:</label> <input type="text" v-model="name">
		</div>
        <div v-if="showTeachersField && getSchoolsAmount > 0" class="row">
            <label for="teacher">{{$t("teacher")}}</label>
            <select name="teacher" v-model="selectedTeacher" >
                <option disabled v-bind:value="null">
                    {{$t("defaultSelectOption")}}</option>
                <template v-for="(teachers, parentIndex) in teacherGroups">
                    <option v-if="getSchoolsAmount > 1"
                            disabled
                            v-bind:key="parentIndex"
                            class="styled-option">{{`----  ${parentIndex}  ----`}}</option>
                    <option v-for="teacher in teachers" 
                            v-bind:key="teacher.id" 
                            v-bind:value="teacher">
                        {{teacher.firstName}} {{teacher.lastName}}
                    </option>
                </template>
            </select>
        </div>
        <div class="row">
            <label for="text">{{$t('startDate')}}</label>
            <datepicker v-on:selected="updateStartDate" v-model="initialDate"></datepicker>
        </div>
        <div class="row">
            <label for="text">{{$t('endDate')}}</label>
            <datepicker v-on:selected="updateEndDate" v-model="finalDate"></datepicker>
        </div>
        
		<div class="row">
            <div class="file-upload flex-container">
                <template v-if="!classroom.file">
                    <div class="upload-btn-wrapper" v-tooltip="$t('cvsFile')" >
                        <button class="btn round secondary">{{$t('studentsBatch')}}</button>
                        <input type="file"  accept=".csv" @change="onFileChange">
                    </div>
                </template>
                <template v-else>
                    <span>{{fileName}}</span>
                    <img :src="deleteIcon" class="delete-icon"  @click="removeFile"/>
                </template>
            </div>
		</div>
    </div>
</template>
<script>

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import AuthenticationService from "@/services/AuthenticationService.js";
import { Role } from "@/utils/Roles.js";

export default {
	name: 'NewClassRoom',
	components:{
        Datepicker
    },
    data: function(){
        return{
            fileName: '',
            initialDate: moment().toDate(),
            finalDate: moment().add(1, 'y').toDate()
        }
    },
    computed:{
        deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
        name:{
            get(){
                return this.classroom.name
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'name')
            }
        },
        classroom(){
            return this.$store.state.newClassroom
        }, 
        showTeachersField(){
            let _user = AuthenticationService.getProfile();

            return _user.roleName === Role.DistrictManager.name
                        || _user.roleName === Role.CampusManager.name;
        },
        teacherGroups(){
            return this.$store.state.teacherGroups;
        },
        getSchoolsAmount(){
            return Object.keys(this.$store.state.teacherGroups).length;
        },
        selectedTeacher: {
            get(){
                return this.$store.state.newClassRoomSelectedTeacher;
            },
            set(value){
                this.$store.commit("setNewClassRoomSelectedTeacher", value);
            }
        }
    },
    methods:{
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return; 
            this.fileName = files[0].name
            this.setComponentPropertyValue(files[0], 'file')
        }, 
        removeFile(){
            this.setComponentPropertyValue(undefined, 'file')
            this.fileName = undefined
        }, 
        updateStartDate(e){
            e = new Date(e).toLocaleDateString()
            this.setComponentPropertyValue(e, 'startDate')
        }, 
        updateEndDate(e){
            e = new Date(e).toLocaleDateString()
            this.setComponentPropertyValue(e, 'endDate')
        }, 
        setComponentPropertyValue(value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.classroom, propertyName: propertyName, value: value})
        }
    }, 
    beforeDestroy(){
        for(let key of Object.keys(this.classroom)){
            this.setComponentPropertyValue(undefined, key)
        }

        this.$store.commit("setNewClassRoomSelectedTeacher", null);
    }, 
    mounted(){
        this.setComponentPropertyValue(this.initialDate, 'startDate')
        this.setComponentPropertyValue(this.finalDate, 'endDate')
        
        if(this.showTeachersField){
            this.$store.dispatch("getTeacherGroups");
        }
        else{
            let _user = AuthenticationService.getProfile();

            this.$store.commit("setNewClassRoomSelectedTeacher", _user);
        }
    }
}
</script>
<style lang="less" scoped>
.new-classroom{
	min-height: 196px;
	width: 460px;
	margin: 0 auto;
	position: relative;
	padding: 16px;
	padding-bottom: 24px;
	box-sizing: border-box;

	> .row{
        margin-top: 16px;
		.btn{

			margin-left: auto;
		}
	}

	.row:first-child{
		margin-top: 0;
    }
    .date-setter{
        margin-left: 8px;
    }
    label, span:not(.legend){
        align-self: center;
        height: 28px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        margin-right: 16px;
    }

    span:not(.legend){
        margin-left: 16px;
        font-weight: 500;
    }

    input[type="text"]{
        max-height: 24;
    }

    .row:nth-child(3),
    .row:nth-child(4){
        label{
            min-width: 80px;
            max-width: 80px;
        }
    }

    .file-upload{
        margin: auto;
    }

    .upload-btn-wrapper {
        margin: auto;
        position: relative;
        overflow: hidden;
        display: inline-block;
        input{
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            height: 100%;
            width: 100%;
        }
    }

    .styled-option{
        font-size: 14px;
        background-color: #e5e6e8;
    }
}
</style>


