var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "class-management row" },
    [
      _c(
        "div",
        { staticClass: "class-room-container column" },
        [
          _vm._l(_vm.clasrooms, function(classroom) {
            return _c("class-room", {
              key: classroom.id,
              attrs: { classroom: classroom }
            })
          }),
          _vm.showModal
            ? _c(
                "modal",
                {
                  attrs: {
                    "click-handler": _vm.createClassRoom,
                    "text-button": _vm.$t("create")
                  },
                  on: {
                    close: function($event) {
                      _vm.showModal = false
                    }
                  }
                },
                [_c("new-class-room")],
                1
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn secondary",
              on: {
                click: function($event) {
                  _vm.showModal = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("addClass")))]
          )
        ],
        2
      ),
      _c("class-room-panel")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }