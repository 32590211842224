<template>
    <div class="classroom column">
		<div class="row">
			<span class="legend">{{name}}</span>
			<button class="btn secondary" @click="showModal = !showModal">{{$t('delete')}}</button>
		</div>
		<div class="row">
			<label>{{$t('classPeriod')}}</label> <span>{{timePeriod}}</span>
			<label>{{$t('teacher')}}</label> <span>{{teacherName}}</span>
		</div>
		<div class="row">
			<label>{{$t('studentsNumber')}}</label> <span>{{classroom.students.length}}</span>
		</div>
		<div class="row">
			<button class="btn round primary" @click="updateSelectedClassroom"> {{$t('classDetails')}}</button>
		</div>
		<modal v-if="showModal" :click-handler="removeClassroom"
				text-button="Remove"  @close="showModal = false">
				<div class="modal-message row">
					<span>{{$t('removeClassroomMessage')}}</span>
				</div>
		</modal>
    </div>
</template>
<script>
import Modal from '@/common/Modal.vue'
export default {
	name: 'ClassRoom',
	components:{
		Modal
	},
	props:{
		classroom: Object
	},
	data(){
		return{
			showModal: false
		}
	}, 
	computed:{
		teacherName(){
			let teacher = this.classroom.teachers[0]
			return  `${teacher.firstName} ${teacher.lastName}`
		}, 
		timePeriod(){
			return ` ${new Date(this.classroom.startDate).toLocaleDateString()} -
					${new Date(this.classroom.endDate).toLocaleDateString()}`
		},
		name(){
			return this.classroom.name
		},
	}, 
	methods:{
		updateSelectedClassroom(){
			this.$store.commit('updateSelectedClassroom', this.classroom)
			this.$router.push({
				name: 'ClassRoomDetails',
				query:{
					classroom: this.classroom.id
				}
			})
		}, 
		removeClassroom(){
			this.$store.dispatch('removeClassroom', this.classroom)
		}, 
	}
}
</script>
<style lang="less" scoped>

.classroom{
	min-height: 196px;
	width: 704px;
	margin: 0 auto;
	margin-top: 16px;
	border-radius: 4px;
	background-color: #F6F7FA;
	box-shadow: var(--primary-shadow);
	position: relative;
	padding: 16px;
	padding-bottom: 24px;
	box-sizing: border-box;

	.row{
		margin-top: 16px;
		margin-left: 16px;
		.btn:first-of-type{
			margin-left: auto;
			margin-right: 8px;
		}
	}

	.row:first-child{
		margin-top: 0;
		position: relative;
	}
    label, span:not(.legend){
        height: 28px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }

    span:not(.legend){
        margin-left: 16px;
        font-weight: 500;
    }
    label:nth-child(3){
        margin-left: 50px;
	}
	
	&:first-of-type{
		margin-top: 0;
	}

	.modal-message{
		height: 80px;
		span{
			margin: auto;
		}
	}

}
</style>


