var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-classroom column" }, [
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "legend" }, [
        _vm._v(_vm._s(_vm.name || _vm.$t("class")))
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("class")) + ":")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name"
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          }
        }
      })
    ]),
    _vm.showTeachersField && _vm.getSchoolsAmount > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { attrs: { for: "teacher" } }, [
            _vm._v(_vm._s(_vm.$t("teacher")))
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedTeacher,
                  expression: "selectedTeacher"
                }
              ],
              attrs: { name: "teacher" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedTeacher = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { disabled: "" }, domProps: { value: null } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("defaultSelectOption"))
                  )
                ]
              ),
              _vm._l(_vm.teacherGroups, function(teachers, parentIndex) {
                return [
                  _vm.getSchoolsAmount > 1
                    ? _c(
                        "option",
                        {
                          key: parentIndex,
                          staticClass: "styled-option",
                          attrs: { disabled: "" }
                        },
                        [_vm._v(_vm._s("----  " + parentIndex + "  ----"))]
                      )
                    : _vm._e(),
                  _vm._l(teachers, function(teacher) {
                    return _c(
                      "option",
                      { key: teacher.id, domProps: { value: teacher } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(teacher.firstName) +
                            " " +
                            _vm._s(teacher.lastName) +
                            "\n                    "
                        )
                      ]
                    )
                  })
                ]
              })
            ],
            2
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("label", { attrs: { for: "text" } }, [
          _vm._v(_vm._s(_vm.$t("startDate")))
        ]),
        _c("datepicker", {
          on: { selected: _vm.updateStartDate },
          model: {
            value: _vm.initialDate,
            callback: function($$v) {
              _vm.initialDate = $$v
            },
            expression: "initialDate"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("label", { attrs: { for: "text" } }, [
          _vm._v(_vm._s(_vm.$t("endDate")))
        ]),
        _c("datepicker", {
          on: { selected: _vm.updateEndDate },
          model: {
            value: _vm.finalDate,
            callback: function($$v) {
              _vm.finalDate = $$v
            },
            expression: "finalDate"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "file-upload flex-container" },
        [
          !_vm.classroom.file
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("cvsFile"),
                        expression: "$t('cvsFile')"
                      }
                    ],
                    staticClass: "upload-btn-wrapper"
                  },
                  [
                    _c("button", { staticClass: "btn round secondary" }, [
                      _vm._v(_vm._s(_vm.$t("studentsBatch")))
                    ]),
                    _c("input", {
                      attrs: { type: "file", accept: ".csv" },
                      on: { change: _vm.onFileChange }
                    })
                  ]
                )
              ]
            : [
                _c("span", [_vm._v(_vm._s(_vm.fileName))]),
                _c("img", {
                  staticClass: "delete-icon",
                  attrs: { src: _vm.deleteIcon },
                  on: { click: _vm.removeFile }
                })
              ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }