var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "classroom column" },
    [
      _c("div", { staticClass: "row" }, [
        _c("span", { staticClass: "legend" }, [_vm._v(_vm._s(_vm.name))]),
        _c(
          "button",
          {
            staticClass: "btn secondary",
            on: {
              click: function($event) {
                _vm.showModal = !_vm.showModal
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("delete")))]
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("classPeriod")))]),
        _c("span", [_vm._v(_vm._s(_vm.timePeriod))]),
        _c("label", [_vm._v(_vm._s(_vm.$t("teacher")))]),
        _c("span", [_vm._v(_vm._s(_vm.teacherName))])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("studentsNumber")))]),
        _c("span", [_vm._v(_vm._s(_vm.classroom.students.length))])
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "button",
          {
            staticClass: "btn round primary",
            on: { click: _vm.updateSelectedClassroom }
          },
          [_vm._v(" " + _vm._s(_vm.$t("classDetails")))]
        )
      ]),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.removeClassroom,
                "text-button": "Remove"
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "modal-message row" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("removeClassroomMessage")))])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }